import http from "../axios/http"

//大脑列表
export let getBrainList = (params) => {
    return http.get("/brain/brain_list", { params })
}
//大脑列表添加
export let postBrainAdd = (params) => {
    return http.post("/brain/brain_add", params)
}
//大脑删除
export let postBrainDel = (params) => {
    return http.post("/brain/brain_del", params)
}
//大脑列表添加
export let postBrainEdit = (params) => {
    return http.post("/brain/brain_edit", params)
}
