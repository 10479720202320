<template>
  <div class="BrainSetup page">
    <a-button type="primary" style="margin-bottom: 15px" @click="handleAdd">
      添加引擎
    </a-button>
    <a-table :columns="columns" :data-source="data" rowKey="sb_id">
      <template #status="{ record }">
        <!-- {{ record.status === "1" ? "开启" : "关闭" }} -->
        <a-switch
          v-model:checked="record.status"
          @change="statusChange($event, record)"
        ></a-switch>
      </template>
      <template #brain_type="{ record }">
        {{ record.brain_type === 1 ? "科大讯飞" : "阿里云" }}
      </template>
      <template #operation="{ record }">
        <a-button
          type="primary"
          style="margin-right: 4px"
          size="small"
          @click="handleEdit(record)"
        >
          编辑
        </a-button>
        <a-popconfirm title="确定删除吗?" @confirm="handleDelete(record.sb_id)">
          <a-button size="small"> 删除 </a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :title="sb_id ? '编辑引擎' : '添加引擎'"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="brain-model"
      @ok="handleOk"
      @cancel="formStateRest"
    >
      <a-form :model="formState" style="margin-right: 15px">
        <a-form-item label="引擎名称" required>
          <a-input
            placeholder="请输入"
            v-model:value="formState.name"
          ></a-input>
        </a-form-item>
        <a-form-item label="APP ID" required>
          <a-input
            placeholder="请输入"
            v-model:value="formState.app_id"
          ></a-input>
        </a-form-item>
        <a-form-item label="引擎厂商" required>
          <a-select placeholder="请选择" v-model:value="formState.brain_type">
            <a-select-option :value="1">科大讯飞</a-select-option>
            <a-select-option :value="2">阿里云</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Key" required>
          <a-input
            placeholder="请输入"
            v-model:value="formState.app_key"
          ></a-input>
        </a-form-item>
        <a-form-item label="密钥" required>
          <a-input
            placeholder="请输入"
            v-model:value="formState.app_secret"
          ></a-input>
        </a-form-item>
        <a-form-item label="状态">
          <a-switch v-model:checked="formState.status"></a-switch>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import {
  postBrainAdd,
  getBrainList,
  postBrainDel,
  postBrainEdit,
} from "../../../service/brainsetup.js";
import { onMounted } from "@vue/runtime-core";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "序号",
    dataIndex: "index",
  },
  {
    title: "引擎名称",
    dataIndex: "sb_name",
  },
  {
    title: "引擎厂商",
    dataIndex: "brain_type",
    slots: {
      customRender: "brain_type",
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "BrainSetup",
  setup() {
    const state = reactive({
      data: [],
      visible: false,
      page: 1,
      sb_id: undefined,
    });
    const formState = reactive({
      name: undefined,
      app_id: undefined,
      brain_type: undefined,
      app_key: undefined,
      app_secret: undefined,
      status: false,
    });
    const formStateRest = () => {
      formState.name = undefined;
      formState.app_id = undefined;
      formState.brain_type = undefined;
      formState.app_key = undefined;
      formState.app_secret = undefined;
      formState.status = false;
      state.sb_id = undefined;
    };
    onMounted(() => {
      init();
    });
    const handleAdd = () => {
      state.visible = true;
    };
    const handleOk = async () => {
      console.log(formState);
      const params = {
        name: formState.name,
        app_id: formState.app_id,
        brain_type: formState.brain_type,
        app_key: formState.app_key,
        app_secret: formState.app_secret,
        status: formState.status ? 1 : 2,
      };
      if (state.sb_id) {
        params.sb_id = state.sb_id;
        const res = await postBrainEdit({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.visible = false;
          state.sb_id = undefined;
          formStateRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postBrainAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.visible = false;
          formStateRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const init = async () => {
      const res = await getBrainList({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
      });
      state.data = res.data.data.list;
      state.data.forEach((item, index) => {
        item["index"] = index + 1;
        item.status === "1" ? (item.status = true) : (item.status = false);
      });
    };
    const handleDelete = async (sb_id) => {
      console.log(sb_id);
      const res = await postBrainDel({
        access_token: sessionStorage.getItem("token"),
        sb_id,
      });
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    const handleEdit = (record) => {
      console.log(record);
      state.visible = true;
      state.sb_id = record.sb_id;
      formState.name = record.sb_name;
      formState.app_id = record.app_id;
      formState.brain_type = record.brain_type;
      formState.app_key = record.app_key;
      formState.app_secret = record.app_secret;
      formState.status = record.status;
    };
    const statusChange = async(e, record) => {
      console.log(e, record);
      // handleOk()
      const params = {
        name: record.sb_name,
        app_id: record.app_id,
        brain_type: record.brain_type,
        app_key: record.app_key,
        app_secret: record.app_secret,
        status: e ? 1 : 2,
        sb_id : record.sb_id
      };
      const res = await postBrainEdit({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          init();
        } else {
          message.error(res.data.msg);
        }
    };
    return {
      columns,
      ...toRefs(state),
      formState,
      handleAdd,
      handleOk,
      formStateRest,
      handleDelete,
      handleEdit,
      statusChange,
    };
  },
};
</script>

<style>
.brain-model .ant-form-item-label {
  min-width: 85px;
}
</style>
<style scoped>
.BrainSetup {
  padding: 15px;
}
</style>
